import React from "react"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { SEO } from "./seo"

export default ({
  children,
  title,
  description,
  image,
  pathname,
  isArticle,
  publicationDate,
  lastUpdated,
  setCanonical,
}) => {
  return (
    <>
      <Header />
      <main className="md:pt-20">
        <SEO
          title={title}
          description={description}
          image={image}
          pathname={pathname}
          isArticle={isArticle}
          publicationDate={publicationDate}
          lastUpdated={lastUpdated}
          setCanonical={setCanonical}
        />
        {children}
      </main>
      <Footer />
    </>
  )
}
