import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

export const SEO = ({
  title,
  description,
  image,
  pathname,
  isArticle,
  publicationDate,
  lastUpdated,
  setCanonical,
}) => {
  // get our site data
  const data = useStaticQuery(query)

  // deconstruct site data for use
  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      subtitle,
      twitterSite,
      twitterCreator,
      language,
      region,
      author,
      owner,
      defaultImage,
      podcastFeedSlug,
    },
  } = data.site

  const seo = {
    url: `${siteUrl}${pathname || ""}`,
    title: title == null ? defaultTitle : title + ` - ` + defaultTitle,
    description: description || subtitle + ` ` + defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // https://schema.org/WebPage

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: seo.url,
    name: seo.title,
    headline: seo.title,
    description: seo.description,
    datePublished: publicationDate,
    dateModified: lastUpdated || buildTime,
    inLanguage: language,
    mainEntityOfPage: seo.url,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Organization",
      name: owner,
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: owner,
    },
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultImage}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (isArticle) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      url: seo.url,
      name: seo.title,
      headline: seo.title,
      description: seo.description,
      datePublished: publicationDate,
      dateModified: lastUpdated,    
      inLanguage: language,
      mainEntityOfPage: seo.url,
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Organization",
        name: owner,
      },
      copyrightYear: new Date().getFullYear(),
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: owner,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${defaultImage}`,
        },
      },
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
    }
    // Push current article into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  return (
    <Helmet>
      {/* Standard head tags */}
      <html lang={language} />
      <title>{seo.title}</title>
      {setCanonical && <link rel="canonical" href={seo.url} />}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      
      {/* link to podcast rss feed for site root/home page */}
      {(seo.url === siteUrl+`/`) && <link rel="alternate" type="application/rss+xml" title={defaultTitle} href={siteUrl+podcastFeedSlug} />}

      {/* Open graph tags */}
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:locale" content={language + `_` + region} />
      <meta property="og:image" content={seo.image} />
      {!isArticle && <meta property="og:type" content="website" />}
      {isArticle && <meta property="og:type" content="article" />}

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content={twitterSite} />
      <meta property="twitter:creator" content={twitterCreator} />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image} />
      

      {/* schema.org tags */}
      {!isArticle && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
            {isArticle && (
        <script type="application/ld+json">
          {JSON.stringify(schemaArticle)}
        </script>
      )}

      {/* Breadcrumbs */}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        subtitle
        twitterSite
        twitterCreator
        language
        region
        author
        owner
        defaultImage: image
        podcastFeedSlug
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  isArticle: PropTypes.bool,
  publicationDate: PropTypes.string,
  lastUpdated: PropTypes.string,
  setCanonical: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  isArticle: false,
  publicationDate: null,
  lastUpdated: null,
  setCanonical: true,
}
