import { Link } from "gatsby"
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export const Header = () => {
  const { title } = useSiteMetadata()
  return (
    <header className="md:w-full md:fixed bg-indigo-900 px-8 z-50">
      <div className="md:max-w-6xl md:mx-auto">
        <nav className="flex items-center justify-between flex-wrap bg-indigo-900 py-6">
        <Link to="/" className="flex items-center flex-shrink-0 text-white mr-6">
          <svg
            className="fill-current h-8 w-8 mr-2"
            width="54"
            height="54"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 407.4 375"
          >
            <g>
	<path d="M327.5,209.9H91.1l-25.4,50.8h23V375H330V260.7h23L327.5,209.9z M319.4,222.6l12.7,25.4H232l-12.7-25.4H319.4z M98.7,222.6
		h100.1L186.1,248H86L98.7,222.6z M317.3,362.3H215.7v-44.5h-12.7v44.5H101.3V260.7h92.8l8.8-17.6v55.7h12.7v-55.7l8.8,17.6h92.8
		V362.3z"/>
	<path d="M114,311.5h38.1v12.7H114V311.5z"/>
	<path d="M114,336.9h38.1v12.7H114V336.9z"/>
	<path d="M164.8,336.9h12.7v12.7h-12.7V336.9z"/>
	<g>
		<path d="M169.7,34.7H27c-14.9,0-27,12.1-27,27v87.2c0,14.9,12.1,27,27.1,27v39.8l56.9-39.6h85.8c14.9,0,27-12.1,27-27V61.7
			C196.7,46.8,184.6,34.7,169.7,34.7z M185.1,148.9c0,8.6-6.9,15.5-15.5,15.5H80.5l-41.7,29v-29H27.2c-8.6,0-15.5-6.9-15.5-15.5
			V61.6c0-8.6,6.9-15.5,15.5-15.5h142.4c8.6,0,15.5,6.9,15.5,15.5V148.9z"/>
		<path d="M52.9,75.6h91.3v11.4H52.9V75.6z"/>
		<path d="M52.9,100.2h91.3v11.4H52.9V100.2z"/>
		<path d="M52.9,124.4h91.3v11.4H52.9V124.4z"/>
	</g>
	<g>
		<path d="M210.7,27v87.4c0,14.9,12.1,27,27,27h85.8l56.9,39.6v-39.8c14.9-0.1,27.1-12.2,27.1-27V27c0-14.9-12.1-27-27-27L237.7,0
			C222.8,0,210.7,12.1,210.7,27z M222.4,26.9c0-8.6,6.9-15.5,15.5-15.5h142.4c8.6,0,15.5,6.9,15.5,15.5v87.3
			c0,8.6-6.9,15.5-15.5,15.5h-11.5v29l-41.7-29h-89.1c-8.6,0-15.5-6.9-15.5-15.5V26.9z"/>
		<path d="M354.6,52.3h-91.3V40.9h91.3V52.3z"/>
		<path d="M354.6,76.9h-91.3V65.5h91.3V76.9z"/>
		<path d="M354.6,101.1h-91.3V89.8h91.3V101.1z"/>
	</g>
</g>
          </svg>
          <span className="font-semibold text-xl tracking-tight">{title}</span>
        </Link>
        <div className="w-full block flex md:flex md:items-center md:w-auto">
          <div className="text-sm font-medium md:flex-grow">
            <Link
              to="/blog/"
              className="block mt-4 md:inline-block md:mt-0 text-gray-500 hover:text-white mr-4"
            >
              Read
            </Link>
            <Link
              to="/podcast/"
              className="block mt-4 md:inline-block 
      md:mt-0 text-gray-500 hover:text-white mr-4"
            >
              Listen
            </Link>
            <Link
              to="/#connect"
              className="block mt-4 md:inline-block md:mt-0 text-gray-500 hover:text-white mr-4"
            >
              Connect
            </Link>
          </div>
        </div>
      </nav>
      </div>
    </header>
  )
}
