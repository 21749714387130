import { useStaticQuery, graphql } from "gatsby"

export const useSiteCopyright = () => {
  const data = useStaticQuery(
    graphql`
      query SiteCopyright {
        site {
          siteMetadata {
            copyright
          }
        }
      }
    `
  )
  return data.site.siteMetadata.copyright;
}