import React from "react"
import { Link } from "gatsby"
import { useSiteCopyright } from "../hooks/use-site-copyright"

export const Footer = () => {
  const copyright = useSiteCopyright();
  return (
  <footer className="md:w-full bg-indigo-900 px-8">
    <div className="md:max-w-6xl md:mx-auto">
    <div className="flex items-center justify-between bg-indigo-900 py-6">
        <div className="w-full block flex md:flex md:items-center md:w-auto">
        <div className="text-sm md:flex-grow">
        <Link to="/blog/" className="block mt-4 md:inline-block md:mt-0 text-gray-500 hover:text-white mr-4">
        Read
      </Link>
      <Link to="/podcast/" className="block mt-4 md:inline-block md:mt-0 text-gray-500 hover:text-white mr-4">
        Listen
      </Link>
      <Link to="/#connect" className="block mt-4 md:inline-block md:mt-0 text-gray-500 hover:text-white mr-4">
        Connect
      </Link>
      <Link to="/about/" className="block mt-4 md:inline-block md:mt-0 text-gray-500 hover:text-white mr-4">
        About
      </Link>
      <Link to="/contact/" className="block mt-4 md:inline-block md:mt-0 text-gray-500 hover:text-white">
        Contact
      </Link>
        </div>
      </div>
      <div>
        <p className="text-gray-500 text-xs">
          {copyright}<br/> 
          All rights reserved.
        </p>
      </div>
    </div>
    </div>
  </footer>
  )
}
